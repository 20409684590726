import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

function StaffAndBoardModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row style={{width: '100%'}}>
                    <Col>
                    <Img fluid={props.imgFluid} alt={props.staffName} className="rounded-circle d-block m-auto" style={{width: '150px', height: '150px'}} />
                    </Col>
                    <Col>
                        <div>
                            <h3>{props.staffName}{' '}{props.lastName}</h3>
                            <p>
                                {/* {props?.ages && (<em>{props.ages}</em>)}<br /> */}
                            {props?.businessName && (<span>{props.businessName}</span>)}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
            {props?.ages && (<p><strong>Ages:</strong> {props.ages}</p>)}
            
            {props?.service && (<p><strong>Areas of Expertise:</strong> {props.service.join(', ')}</p>)}
            {/* {props?.info && (<p><strong>Areas of Expertise:</strong>{' '}{props?.info && (<span>{props.info}</span>)}</p>) } */}
            {props?.modalities && ( <p><strong>Primary Modalities:</strong>{' '}{props.modalities}</p> )}
            {props?.insurance && ( <p><strong>Insurance:</strong>{' '}{props.insurance.join(', ')}</p>)}
            
            <p>
            {props?.businessName && 
                (<><strong>{props.businessName}</strong><br />
                {props?.address && (<>{props.address}<br /></>)}
                {props?.address2 && (<>{props.address2}<br /></>)}
            </>)}
            
            {props?.website && (<><strong>Website: </strong><a href={`${props.website}`} target="_blank" rel="noopener noreferrer">{props.website}</a></>)}<br />
            {props?.phone && (<><strong>Phone: </strong><a href={`tel:${props.phone}`}>{props.phone}</a></>)}<br />
            {props?.email && (<><strong>Email: </strong><a href={`mailto:${props.email}`}>{props.email}</a></>)}<br />            
            </p>
            {props?.bio && (<p>{props.bio}</p>)}
            </Modal.Body>
      </Modal>
    )
}

export default StaffAndBoardModal