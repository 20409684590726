import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'
import StaffAndBoardModalButton from "../../HomePage/StaffAndBoard/StaffAndBoardModalButton"

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }

`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
`;


const SchoolBased = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>2020-21 School-Based Counseling Program</h2>
          <h3>Purpose</h3>
          <p>Teton Valley Mental Health Coalition’s School-Based Counseling Program provides funding for free onsite professional mental health counseling services for Teton Valley public-school students in need. This program is intended to help mitigate the serious and sometimes life-threatening consequences of untreated mental illness in our local youth population by improving access to mental health services for children and teens in crisis.</p>
          <h3>Description</h3>
          <p>Through a collaborative effort with Teton School District 401, Teton Valley Mental Health Coalition provides FREE mental health services for students attending local public schools at the elementary, middle and high school grade levels. Students may access this program through a referral from the school counselor. For questions on how to receive school-based services for your child, please contact your school’s counselor directly.</p>
          <h3>Program Goals</h3>
          <p>The primary goals of the Teton Valley Mental Health Coalition’s School-Based Counseling Program are to:</p>
          <ul>
<li>Increase access to critical mental health resources for local children and teens</li>
<li>Overcome significant barriers to mental health services for Teton Valley youth</li>
<li>Reduce the suffering of local children and teens by providing them with appropriate mental health services that teach healthy coping mechanisms and improve quality of life </li>
<li>Reduce and/or prevent suicidal thoughts and behaviors experienced by local children and teens in crisis</li>
<li>Provide professional mental health support and consultation to local public-school administrators and school counselors</li>
          </ul>

        </TabContent>
    </>
    )
}

export default SchoolBased