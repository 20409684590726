import React from "react"
import { Button } from 'react-bootstrap'
import { motion } from 'framer-motion'
import EligibilityCriteriaModal from "./EligibilityCriteriaModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkSquareAlt } from "@fortawesome/pro-solid-svg-icons"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        
        <div className="text-center">
            
        <Button onClick={() => setModalShow(true)} style={{
                cursor: 'pointer',
            }} 
            role="button"
            className="purpleButton btn-lg btn-block">

    {props.buttonTitle} {' '}
            <FontAwesomeIcon
                icon={faExternalLinkSquareAlt}
            />
    </Button>

    <EligibilityCriteriaModal
        show={modalShow}
        onHide={() => setModalShow(false)} 
        title={props.title}
        content={props.content}
    />
        </div>

    )
}

export default Modal