import React from 'react'
import { Container } from 'react-bootstrap'

const CounselingCanChangeLives = () => {
    return (
        <>
        <Container fluid id="counseling-programs" style={{
            padding: '3rem'
        }}>
        <h2 className="sectionTitle">
        Free Community Counseling Program
          </h2>
          {/* <h3 className="subSectionTitleLight">
          FREE Mental Health Services available to Teton Valley residents!
          </h3> */}
          <ul style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              listStyleType: 'none',
              color: '#302d5e'
              }}>
<li>Are you feeling stressed, overwhelmed, anxious, or depressed?</li>
<li>Has your mental health been negatively impacted by recent events?</li>
<li>Would you benefit from speaking to a professional mental health counselor?</li>
<li>Are financial concerns preventing you from seeking out counseling?</li>
          </ul>
          <p style={{
              color: '#302d5e',
              fontSize: '1.25rem',
              textAlign: 'center'
          }}>

          </p>
          <h3 className="subSectionTitleLight">If you answered YES to any of these questions and you currently reside in Teton County, ID or Alta, WY, local help is available! We offer up to six FREE counseling sessions for local residents in need.
</h3>
        </Container>
        </>
    )
}

export default CounselingCanChangeLives