import React from "react"
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import StaffAndBoardModal from "./StaffAndBoardModal"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false)
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1}
    }
    return (
        <motion.div
            animate={{ 
                scale: [0.5, 1],
            }}
            transition={{ duration: 0.5 }}
        >

        
        <div className="text-center">
            
        <span onClick={() => setModalShow(true)} style={{
                cursor: 'pointer',
            }} 
            role="button"
            className="staffMember">

        <div style={{marginBottom: '1rem'}}><Img fluid={props.imgFluid} alt={props.staffName} className="rounded-circle d-block m-auto" style={{maxWidth: '120px', height: '120px'}} />
        </div>
        <span style={{marginTop: '2rem'}}>
            {props.staffName}{' '}{props.lastName}
        </span>
            {/* <span>
                <FontAwesomeIcon
                    icon={faExternalLink}
                    style={{
                        fontSize: '14px',
                        marginLeft: '10px'
                    }}
                    aria-hidden="true"
                    
                />
            </span> */}
            </span><br />
            <span className="staffPosition">
            {props.ages}
            </span>
            

            <StaffAndBoardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                imgFluid={props.imgFluid}
                staffName={props.staffName}
                lastName={props.lastName}
                info={props.info}
                staffPosition={props.staffPosition}
                ages={props.ages}
                businessName={props.businessName}
                address={props.address}
                address2={props.address2}
                website={props.website}
                phone={props.phone}
                email={props.email}
                modalities={props.modalities}
                insurance={props.insurance}
                service={props.service}
                bio={props.bio}
            />
        </div>
        </motion.div>
    )
}

export default Modal