import React, {useEffect, useState} from "react"
import { Router } from "@reach/router"
import NavLink from "../components/NavLink/NavLink"

import { RichText } from "prismic-reactjs"

import { Container, Tab, Nav, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from "../components/PageHero/PageHero"
import PaperBackground from '../components/PaperBackground/PaperBackground'
import CounselingCanChangeLives from "../components/CounselorsAndProgramsPage/CounselingCanChangeLives"
import CounselorsProgramsTab from '../components/CounselorsAndProgramsPage/TabContent/CounselorsProgramsTab'
import ChildFamilyTherapy from '../components/CounselorsAndProgramsPage/TabContent/ChildFamilyTherapy'
import AdultTheraphy from '../components/CounselorsAndProgramsPage/TabContent/AdultTherapy'
import SchoolBased from '../components/CounselorsAndProgramsPage/TabContent/SchoolBased'
import SuicidePrevention from '../components/CounselorsAndProgramsPage/TabContent/SuicidePrevention'
import GriefSupport from "../components/CounselorsAndProgramsPage/TabContent/GriefSupport"
import MentalHealthProTraining from "../components/CounselorsAndProgramsPage/TabContent/MentalHealthProTraining"

import PageHeroBottom from "../components/PageHero/PageHeroBottom"
import AmoebaSlim from "../components/Amoebas/AmoebaSlim"
import SchoolBasedCrisisCounseling from "../components/CounselorsAndProgramsPage/SchoolBasedCrisisCounseling"
import EligibilityCriteria from "../components/CounselorsAndProgramsPage/EligibilityCriteria/EligibilityCriteria"
import Faqs from "../components/CounselorsAndProgramsPage/TabContent/Faqs"




const CounselorsPrograms = ( props ) => {
  const [key, setKey] = useState("family-therapy")

  useEffect(() => {
    setKey(props.location.pathname.id);
    console.log(props.location.pathname);
}, [props.location.pathname]);
    
    const currentTab = props.location.pathname;
    //console.log(`currentTab:  ${currentTab}`)

  //const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  // const heroPhoto = props.data.tetons.childImageSharp.fluid
  const bottomPhoto = props.data.tetonAerial.childImageSharp.fluid
  // const heroMobilePhoto = props.data.tetonsMobile.childImageSharp.fluid

  const document = props.data.allPrismicPage.edges[0].node.data

  const prismicHeroImage = props.data.allPrismicPage.edges[0].node.data.hero_image.localFile


  return (
  <Layout>
     <SEO title="Teton Valley Mental Health Coalition Counselors and Programs" />
    <PageHero
      description={<><RichText render={document.hero_content.raw} /></>}
      image={prismicHeroImage}
      heading={document.hero_title.text}
      style={{color: '#434081'}}
      fillColor="#C97D60"
      bgColor="#C97D60"
    />
    <PaperBackground>
    <CounselingCanChangeLives />
      <EligibilityCriteria />
    
    {/* <div style={{ marginTop: '-4px', transform: 'rotate(180deg)'}}>
  <AmoebaSlim
  fillColor="#C97D60"
/>
</div> */}
    <Container className="py-4">
    <Tab.Container defaultActiveKey="counselors" className="jumpTarget">
  <Row>
    <Col sm={3}>
      <Nav variant="pills"
        className="flex-column"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Nav.Item>
          <NavLink to="/counselors-programs/counselors-programs" eventKey="counselors" id="counselors-programs" role="tab" partiallyActive={true} className={`nav-link resources-nav ${currentTab === '/counselors-programs' ? 'active' : ''}`}>Counselors &amp; Programs</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/child-family-therapy" eventKey="family" id="family-therapy" role="tab" partiallyActive={true} className="nav-link resources-nav">Child &amp; Family Therapy</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/adult-therapy" eventKey="adult" id="adult-therapy" role="tab" partiallyActive={true} className="nav-link">Adult Therapy</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/school-based-mental-health-services" eventKey="school" id="school-based" role="tab" partiallyActive={true} className="nav-link resources-nav">School Based Mental Health Services</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/suicide-prevention-awareness" eventKey="suicide" id="suicide-prevention" role="tab" partiallyActive={true} className="nav-link resources-nav">
            Suicide Prevention &amp; Awareness
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/grief-support" eventKey="grief" id="grief-support" role="tab" partiallyActive={true} className="nav-link resources-nav">
            Grief Support
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/faq" eventKey="faq" id="frenquently-asked-questions" role="tab" partiallyActive={true} className="nav-link resources-nav">
            FAQs
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/counselors-programs/mental-health-professional-training" eventKey="training" id="mental-health-professional-training" role="tab" partiallyActive={true} className="nav-link resources-nav">
            Mental Health Professional Training
          </NavLink>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
    <Tab.Content>
      {/* See gatsby-browswer.js for scollWillUpdate method what handles focus for these routes */}
    <Router basepath="/counselors-programs" primary={true}>
      <CounselorsProgramsTab path="/counselors-programs" />
      <CounselorsProgramsTab path="/" />
      <ChildFamilyTherapy path="child-family-therapy" />
      <AdultTheraphy path="adult-therapy" />
      <SchoolBased path="school-based-mental-health-services" />
      <SuicidePrevention path="suicide-prevention-awareness" />
      <GriefSupport path="grief-support" />
      <Faqs path="faq" />
      <MentalHealthProTraining path="mental-health-professional-training" />
      
           
    </Router>
    </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
</Container>
<div style={{bottom: 0}}>
  <AmoebaSlim
  fillColor="#928D63"
  style={{ bottom: 0 }}
/>
</div>

<SchoolBasedCrisisCounseling />
<PageHeroBottom
      fillColor="#928D63"
      bottomImage={bottomPhoto}
      heading="Teton Valley’s mental health matters and we’re here to support you."
    />
    </PaperBackground>
  </Layout>
  )
}

export const query = graphql`
{
  allPrismicPage(filter: {uid: {eq: "counselors-programs"}}) {
    edges {
      node {
        data {
          hero_title {
            text
          }
          hero_content {
            raw
          }
          hero_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  allPrismicCounsel {
    edges {
      node {
        data {
          website {
            url
          }
          services {
            service
          }
          professional_bio {
            raw
          }
          primary_modalities {
            text
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED, transformOptions: {cropFocus: CENTER})
              }
            }
            alt
          }
          phone {
            text
          }
          last_name {
            text
          }
          insurance {
            insurance1
          }
          first_name {
            text
          }
          email {
            text
          }
          business_name {
            text
          }
          areas_of_expertise {
            text
          }
          ages {
            text
          }
        }
      }
    }
  }
  allFile(filter: {sourceInstanceName: {eq: "pages"}, relativeDirectory: {eq: "counselors-and-programs"}, name: {eq: "index"}}) {
    edges {
      node {
        childMarkdownRemark {
          html
          frontmatter {
            title
            description
            heading
            photo {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 150, cropFocus: ATTENTION) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  tetons: file(relativePath:{eq:"tetons-cropped@2x.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tetonsMobile: file(relativePath:{eq:"tetons-mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tetonAerial: file(relativePath:{eq:"teton-aerial.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default CounselorsPrograms
