import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import AmoebaSlim from '../Amoebas/AmoebaSlim'

const SchoolBasedCrisisCounseling = () => {
    return (
        <>
        <div style={{
            backgroundColor: '#928D63',
            marginTop: '-2px'
        }}>
            <Container className="pb-4">
                <Row>
                    <Col>
                        <h2 className="sectionTitle" style={{color: 'white'}}>Please give today!</h2>
                        <h3 className="subSectionTitleLight"  style={{color: 'white'}}>Our programs depend on the generosity of local donors. Please consider a gift to help support our efforts to provide critical mental health services for all in our community.</h3>
                        <div className="text-center">
                            <Link to="/donate">
                                <Button className="purpleButton btn-lg">Donate Now!</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default SchoolBasedCrisisCounseling