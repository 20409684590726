import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }

`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
`;


const SuicidePrevention = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        suicidePrevention: file(relativePath: {eq: "NSPL_Logo.jpg"}) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>Suicide Prevention &amp; Awareness</h2>
          <p>If you or a loved one are experiencing emotional distress, please know that YOU ARE NOT ALONE! Help is only a phone call (or text) away.
To reach a trained Crisis Counselor, call 1-800-273-TALK (8255) or text GO to 741741.</p>
        <div className="text-center">
          <Img fluid={data.suicidePrevention.childImageSharp.fluid} alt="National Suicide Prevention Lifeline"  className="d-block m-auto"  style={{width: '250px'}} />
        </div>

        </TabContent>
    </>
    )
}

export default SuicidePrevention