import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

function EligibilityModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row style={{width: '100%'}}>
                    <Col>
                        <div>
                            <h3>{props.title}</h3>
                        </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
            <div>
                <p>{props.content}</p>
            </div>
            </Modal.Body>
      </Modal>
    )
}

export default EligibilityModal