import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }

`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
`;


const Faqs = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>Frequently Asked Questions</h2>
          <p>The Teton Valley Mental Health Coalition was formed in the Fall of 2009 to help foster collaboration among mental health care providers serving Teton Valley. We are registered with the state of Idaho as a 501(c)(3) nonprofit organization and are dedicated to addressing emergent mental health needs in our community.</p>
          <p>Our mission is to promote mental wellness in Teton Valley through education, community action and the coordination of resources. We are clinical mental health providers, local business owners, parents, medical professionals and dedicated community members who share a common passion for fostering mental health in Teton Valley.</p>
          <p>We offer several programs to promote mental wellness in our community, including:</p>
          <ul>
<li>Funding for FREE professional mental health counseling sessions for Teton Valley residents in need</li>
<li>Funding for FREE school-based professional mental health services for Teton Valley children and youth </li>
<li>Suicide awareness and prevention</li>
<li>Community forum to address emergent mental health needs</li>
<li>Mental health training for local mental health providers, medical professionals and interested community members</li>
<li>Grief support groups and community events</li>
<li>Extensive collaboration with community leaders, social service nonprofits, regional mental health agencies and providers </li>
          </ul>
          <p>We thank you for your support!</p>

        {/* <div className="text-center">
          <Img fluid={data.suicidePrevention.childImageSharp.fluid} alt="National Suicide Prevention Lifeline"  className="d-block m-auto"  style={{width: '250px'}} />
        </div> */}

        </TabContent>
    </>
    )
}

export default Faqs