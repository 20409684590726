import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }

`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
  > ol {
    font-weight: 300;
    padding-left: 20px;
  }
`;


const MentalHealthProTraining = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>Mental Health Professional Training</h2>
          <p>Future dates to be determined. To receive notifications for future training opportunities, please sign up for the Teton Valley Mental Health Coalition Listserv.</p>
          <p><strong>To sign up for the Teton Valley Mental Health Coalition Listserv:</strong></p>
          <ol>
          <li>Send an email to carrie@tetonvalleymentalhealth.com and ask to be added to the Listserv.</li>
          <li>An email will be sent to you confirming your subscription.</li>

          </ol>

        {/* <div className="text-center">
          <Img fluid={data.suicidePrevention.childImageSharp.fluid} alt="National Suicide Prevention Lifeline"  className="d-block m-auto"  style={{width: '250px'}} />
        </div> */}

        </TabContent>
    </>
    )
}

export default MentalHealthProTraining