import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft, faLongArrowAltUp } from "@fortawesome/pro-solid-svg-icons"

const StaffAndBoardWrapper = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: 1fr;

@media(min-width: 768px) {
  grid-template-columns: 1fr 1fr 1fr;
 
}

@media (min-width: 1024px) {
  grid-template-columns: 1fr 1fr 1fr;
}
`

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }
    #desktopPanel {
        display: none;
    }
    @media(min-width: 768px) {
        #desktopPanel {
            display: block;
        }
  }
  #mobilePanel {
      display: none;
  }
  @media(max-width: 768px) {
        #mobilePanel {
            display: block;
        }
  }
`;


const CounselorsProgramsTab = () => {
    const data = useStaticQuery(graphql`
    {
        paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
          childImageSharp {
              fluid(quality: 80, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
      }
    `)
  
    return (
        <>
        <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="Family Therapy"  className="rounded"/>
            <HeaderImageWrapper>
            <div id="desktopPanel"><p>Please use the tabs on the left to choose from counselors and services
                <br />
            <FontAwesomeIcon
                className="arrowIcon"
                        icon={faLongArrowAltLeft}
                        aria-hidden="true"
                        style={{
                            color: '#434081',
                            width: '40px',
                            height: 'auto',
                        }}                   
                    />
                    </p></div>
                    <br />
            <div id="mobilePanel"><FontAwesomeIcon
                className="arrowIcon"
                        icon={faLongArrowAltUp}
                        aria-hidden="true"
                        style={{
                            color: '#434081',
                            width: '10px',
                            height: 'auto',
                        }}                   
                    /><p>Please use the tabs above to choose from counselors and services
                
            
                    </p></div>
            </HeaderImageWrapper>
            {/* <p style={{color: '#434081', marginTop: '2rem', fontWeight: '300'}}>Disclaimer: If you or a loved one are experiencing a psychiatric emergency, please call 911 or go to the nearest emergency room. To make an appointment with a local mental health counselor, please contact the provider of your choice directly.</p> */}
        </div>
      </>
    )
}

export default CounselorsProgramsTab