import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import StaffAndBoardModalButton from "../Counselors/StaffAndBoardModalButton"
import { RichText } from 'prismic-reactjs'


const StaffAndBoardWrapper = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: 1fr;

@media(min-width: 768px) {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (min-width: 1024px) {
  grid-template-columns: 1fr 1fr 1fr;
}
`

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }
`;


const ChildFamilyTherapy = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicCounsel(filter: {data: {services: {elemMatch: {service: {eq: "Child & Family Therapy"}}}}}, sort: {fields: data___last_name___text, order: ASC}) {
        edges {
          node {
            data {
              website {
                url
              }
              services {
                service
              }
              professional_bio {
                raw
              }
              primary_modalities {
                text
              }
              photo {
                alt
                localFile {
                  childImageSharp {
                    fluid(trim: 1, cropFocus: CENTER) {
                      src
                    }
                  }
                }
              }
              phone {
                text
              }
              last_name {
                text
              }
              insurance {
                insurance1
              }
              first_name {
                text
              }
              email {
                text
              }
              business_name {
                text
              }
              areas_of_expertise {
                text
              }
              ages {
                text
              }
            }
          }
        }
      }
        genericPortrait: file(relativePath: { eq: "generic-person@2x.png" }) {
            childImageSharp {
              fluid(maxWidth: 200, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
          childImageSharp {
              fluid(quality: 80, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
      }
    `)
    
    const genericPerson = data.genericPortrait.childImageSharp.fluid
    const prismicPerson = data.allPrismicCounsel.edges
  
    return (
        <>
        <div className="mb-4" style={{
            position: 'relative'
        }}>
            {/* <Img fluid={data.paperFamily.childImageSharp.fluid} alt="Family Therapy"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>Counseling tailored to meet the unique developmental needs of children and youth.</p></div>
            </HeaderImageWrapper> */}
            <h3 style={{color: '#434081', fontWeight: '300'}}>Counseling tailored to meet the unique developmental needs of children and youth and help families better communicate and resolve conflict.
</h3>
            <p style={{color: '#434081', fontWeight: '300'}}>Disclaimer: If you or a loved one are experiencing a psychiatric emergency, please call 911 or go to the nearest emergency room. To make an appointment with a local mental health counselor, please contact the provider of your choice directly.</p>
        </div>
    <StaffAndBoardWrapper>
      {prismicPerson.map(({ node }) => {
        console.log(node)
        return (
          <StaffAndBoardModalButton 
          staffName={node.data.first_name.text}
          lastName={node.data.last_name.text}
          service={node.data.services.map((item) => {
            return (
              item.service
            )
            }
          )}
          ages={node.data.ages.text}
          website={node.data.website.url}
          bio={<RichText render={node.data.professional_bio.raw}/>}
          service={node.data.services.map((service_type) => service_type.service)}
          imgFluid={node.data.photo.localFile.childImageSharp.fluid === null ? genericPerson : node.data.photo.localFile.childImageSharp.fluid}
          businessName={node.data.business_name.text}
          phone={node.data.phone.text}
          email={node.data.email.text}
          modalities={node.data.primary_modalities.text}
          insurance={node.data.insurance.map((insurance_types) => insurance_types.insurance1)}
          />
        )
      })
    }
    </StaffAndBoardWrapper>
    </>
    )
}

export default ChildFamilyTherapy