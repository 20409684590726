import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import AmoebaSlim from '../../Amoebas/AmoebaSlim'
import EligibilityModalButton from './EligibilityCriteriaModalButton'

const EligibilityCriteria = () => {
    return (
        <>
            {/* <div style={{bottom: 0, marginBottom: '-2px'}}>
  <AmoebaSlim
  fillColor="#C97D60"
  
/>
</div> */}
    <Container>
        <Row className="text-center mb-4">
          <Col sm={6}>
            <EligibilityModalButton
              buttonTitle="Who Is Eligible"
              title="Who Is Eligible"
              content={<>Client participation is limited to local residents, defined as those who:
              <ul>
              <li>Currently reside in Teton County, ID or Alta, Wyoming; and</li>
              <li>Are experiencing a mental health crisis or emotional distress; and</li>
              <li>Are unable to obtain or pay for mental health counseling through health insurance, private means, or are otherwise ineligible to receive mental health services through 
              the Idaho Department of Health and Welfare (Medicaid).</li></ul></>}
            />
          </Col>
          <Col sm={6}>
          <EligibilityModalButton
              buttonTitle="How To Access Free Counseling"
              title="How To Access Free Counseling"
              content={<>The Mental Health Coalition is a private group of individuals who fundraise each year to reimburse local counselors for providing a limited number of free mental health counseling sessions for individuals who would otherwise be unable to pay for these services. We are not a mental health clinic and do not provide direct mental health services. Each counselor who participates in our free counseling program operates from their own office to provide services to local residents. To access free counseling, interested Teton Valley residents can <a href="mailto:info@tetonvalleymentalhealth.com">contact us</a> to determine their eligibility and schedule an appointment.</>}
            />
          </Col>
        </Row>
        {/* <h3 className="subSectionTitleLight"  style={{color: 'white'}}>The Teton Valley Mental Health Coalition's Community Counseling Program offers up to six FREE counseling sessions for local residents in need. </h3>
          <p style={{
              color: 'white',
              textAlign: 'center',
              fontSize: '1.5rem'
          }}>Client participation is limited to local residents, defined as those who:</p>
          <ul style={{
              fontWeight: 300,
              fontSize: '1.25rem',
              color: 'white'
              }}>
<li>Currently reside in Teton County, ID or Alta, Wyoming; and</li>
<li>Are experiencing a mental health crisis or emotional distress; and</li>
<li>Are unable to obtain or pay for mental health counseling through health insurance, private means, or are otherwise ineligible to receive mental health services through the Idaho Department of Health and Welfare.</li>
          </ul> */}

    </Container>

        </>
    )
}

export default EligibilityCriteria