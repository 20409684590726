import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const HeaderImageWrapper = styled.div`
    position: absolute;
    top: 0px;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        width: 44%;
    }
    > div > p {
        color: #434081;
        font-size: 1.25rem;
        font-weight: 300;
    }

`;

const TabContent = styled.div`
  color: white;

  > p {
    font-weight: 300;
  }
  > ul {
    font-weight: 300;
    padding-left: 10px;
  }
`;


const GriefSupport = () => {
    const data = useStaticQuery(graphql`
    {
      paperFamily: file(relativePath: {eq: "paper-family-cropped.jpg"}) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)
    return (
        <>
        {/* <div className="mb-4" style={{
            position: 'relative'
        }}>
            <Img fluid={data.paperFamily.childImageSharp.fluid} alt="School-Based Counseling Program"  className="rounded"/>
            <HeaderImageWrapper>
            <div><p>2020-21 School-Based Counseling Program</p></div>
            </HeaderImageWrapper>
        </div> */}
        <TabContent>
          <h2>Grief Support Group for Parents</h2>
          <p>Facilitated by Licensed Therapist Christina Riely, LCSW. Funding provided by Teton Valley Mental Health Coalition.</p>
          <h3>A series of free, confidential, supportive group sessions for parents who have experienced the loss of a child.</h3>
          <hr/>
          <h4>First Thursday of every month<br />5:45&mdash;7:00 p.m.<br />Driggs Elementary School, Room C104*</h4>
          <hr />
          <p>Please contact Christina Riley for more details and to register.<br />
          Christina Riley, LCSW<br/>Insights Counseling<br /><a href="tel:307-730-7060">(307) 730-7060</a><br /><a href="mailto:cemderrileylcsw@gmail.com">cemderrileylcsw@gmail.com</a><br /><a href="https://InsightsCounselingandCoaching.com" target="_blank" rel="noopener noreferrer">InsightsCounselingandCoaching.com</a></p>
          <p>Spaces are limted.</p>
          <p>* COVID-19 safety protocols will be in place. Please bring a mask to wear indoors.</p>

        {/* <div className="text-center">
          <Img fluid={data.suicidePrevention.childImageSharp.fluid} alt="National Suicide Prevention Lifeline"  className="d-block m-auto"  style={{width: '250px'}} />
        </div> */}

        </TabContent>
    </>
    )
}

export default GriefSupport